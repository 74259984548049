AddPage.css
/* https://codesandbox.io/p/sandbox/ios-styled-picker-g3xn3?file=%2Fsrc%2Findex.js */
.App {
  font-family: sans-serif;
  text-align: center;
}
.rmc-picker,
.rmc-multi-picker {
  height: 204px;
}
.rmc-multi-picker {
  display: flex;
  align-items: center;
}
.rmc-picker-item {
  font-size: 30px;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #9b9b9b;
  width: 100%;
  box-sizing: border-box;
}
.rmc-picker {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  flex: 1;
  text-align: center;
}
.my-picker-view-item {
    font-size: 30px; /* adjust as needed */
  }
.rmc-picker-mask {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  z-index: 3;
  background-image: linear-gradient(
    to bottom,
    var(--dialogColor) 0,
    rgba(255, 255, 255, 0) 64px,
    rgba(255, 255, 255, 0) 140px,
    var(--dialogColor) 100%
  );
  background-position: top, bottom;
  background-size: 100% 204px;
  background-repeat: no-repeat;
}
.rmc-picker-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.rmc-picker-indicator {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  position: absolute;
  left: 0;
  top: 102px;
  z-index: 3;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
